import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

//投稿一覧画面にページネーション実装
// https://qiita.com/macotok/items/501e581ff826c6755ab7#%E6%8A%95%E7%A8%BF%E4%B8%80%E8%A6%A7%E7%94%BB%E9%9D%A2%E3%81%AB%E3%83%9A%E3%83%BC%E3%82%B8%E3%83%8D%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E5%AE%9F%E8%A3%85
const blogPostList = ({ pageContext }) => (
  <Layout sectionClasses={"t-news"} dataNamespace={"news_list"}>
    <SEO title="NEWS LIST" />

    <div className="t-news__ttl p-title">
      <span className="t-news__ttl__main">NEWS</span><span className="t-news__ttl__sub">INFORMATION&TOPICS</span>
    </div>

    <div className="t-news__list">
      <p className="t-news__list__caption">NEWS LIST</p>
      <ul className="p-updateList">
        {
          pageContext.posts.map((post, index) => (
            <li key={index}>
              <Link to={"/news/" + post.node.wordpress_id}><p><span className="p-updateList__date">{post.node.date}</span><span className="p-updateList__cat">{post.node.categories[0].name}</span></p>{post.node.title}</Link>
            </li>
          ))
        }
      </ul>
      {/*<div className="t-news__paginate">*/}
      {/*  { pageContext.currentPage - 1 > 1*/}
      {/*    ? <Link className="t-news__paginate__prev" to={pageContext.currentPage <= 2 ? '/news' : `/news/page/${pageContext.currentPage - 1}`}>*/}
      {/*        <button className="p-button p-button--prev"></button>*/}
      {/*      </Link>*/}
      {/*    : <Link className="t-news__paginate__prev" to='/news'>*/}
      {/*        <button className="p-button p-button--prev"></button>*/}
      {/*      </Link>*/}
      {/*  }*/}
      {/*  <div className="t-news__paginate__inner">*/}
      {/*    /!* 最初のページ　現在のページ-1　現在のページ　現在のページ+1　現在のページ+2　最後のページ*!/*/}

      {/*    /!* 最初のページ *!/*/}
      {/*    { pageContext.currentPage >= 2 &&*/}
      {/*      <Link className="t-news__paginate__index" to='/news'>1</Link>*/}
      {/*    }*/}
      {/*    /!* … *!/*/}
      {/*    { pageContext.currentPage - 2 > 1 &&*/}
      {/*      <span className="t-news__paginate__index">…</span>*/}
      {/*    }*/}
      {/*    /!* 現在のページ-2 *!/*/}
      {/*    { pageContext.numberOfPages === pageContext.currentPage &&*/}
      {/*      pageContext.numberOfPages >= 4 &&*/}
      {/*        <Link className="t-news__paginate__index" to={`/news/page/${pageContext.currentPage - 2}`}>{pageContext.currentPage - 2}</Link>*/}
      {/*    }*/}
      {/*    /!* 現在のページ-1 *!/*/}
      {/*    { pageContext.currentPage >= 3 &&*/}
      {/*      <Link className="t-news__paginate__index" to={`/news/page/${pageContext.currentPage - 1}`}>{pageContext.currentPage - 1}</Link>*/}
      {/*    }*/}
      {/*    /!* 現在のページ *!/*/}
      {/*    {<Link className="t-news__paginate__index__is__current" to={`/news/page/${pageContext.currentPage}`}>{pageContext.currentPage}</Link>}*/}
      {/*    /!* 現在のページ+1 *!/*/}
      {/*    { pageContext.numberOfPages > pageContext.currentPage + 1 &&*/}
      {/*      <Link className="t-news__paginate__index" to={`/news/page/${pageContext.currentPage + 1}`}>{pageContext.currentPage + 1}</Link>*/}
      {/*    }*/}
      {/*    /!* 現在のページ+2 *!/*/}
      {/*    { pageContext.currentPage === 1 &&*/}
      {/*      pageContext.numberOfPages >= 4 &&*/}
      {/*      <Link className="t-news__paginate__index" to={`/news/page/${pageContext.currentPage + 2}`}>{pageContext.currentPage + 2}</Link>*/}
      {/*    }*/}
      {/*    /!* … *!/*/}
      {/*    { pageContext.numberOfPages > pageContext.currentPage + 2 &&*/}
      {/*      <span className="t-news__paginate__index">…</span>*/}
      {/*    }*/}
      {/*    /!* 最後のページ *!/*/}
      {/*    { pageContext.numberOfPages !== pageContext.currentPage &&*/}
      {/*      <Link className="t-news__paginate__index" to={`/news/page/${pageContext.numberOfPages}`}>{pageContext.numberOfPages}</Link>*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*  { pageContext.numberOfPages === 1 */}
      {/*    ? (<Link className="t-news__paginate__next" to='/news'><button className="p-button p-button--next"></button></Link>)*/}
      {/*    : (pageContext.currentPage + 1 <= pageContext.numberOfPages)*/}
      {/*      ? <Link className="t-news__paginate__next" to={pageContext.currentPage === pageContext.numberOfPages ? `/news/page/${pageContext.currentPage}` : `/news/page/${pageContext.currentPage + 1}`}>*/}
      {/*          <button className="p-button p-button--next"></button>*/}
      {/*        </Link>*/}
      {/*      : <Link className="t-news__paginate__next" to={`/news/page/${pageContext.numberOfPages}`}>*/}
      {/*          <button className="p-button p-button--next"></button>*/}
      {/*        </Link>*/}
      {/*  }*/}
      {/*</div>*/}
    </div>
    {/*
    <Link class="p-pageBackBtn" to="/">
      <div class="c-grid">
        <div class="c-grid__row">
          <div class="c-grid__col">
            <span class="p-pageBackBtn__inner">BACK TO TOP</span>
          </div>
        </div>
      </div>
    </Link>
    */}
  </Layout>
);

export default blogPostList;